










































































































































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  CustomFormType,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto, ServiceDto,
  ServiceManagementCreateOrUpdateDto
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import moment from "moment";

@Component({
  components: {
    AbSelect,
    Ueditor,
    CustomFormEdit
  },
})
export default class CreateServiceOrganization extends Vue {
  @Ref("customForm") customForm!: CustomFormEdit;

  @Ref() readonly dataForm!: ElForm;
  loading = false;
  dataId = 0;
  defaultData: ServiceManagementCreateOrUpdateDto = {};
  isDetail = false;
  form: ServiceManagementCreateOrUpdateDto = {
    extensionObject: {}
  };
  serviceInfo: ServiceDto = {
    type: {},
    serviceOrganization: {
      name: undefined
    },
    serviceObjectType: {},
    province: {
      provinceName: undefined
    },
    city: {
      cityName: undefined
    },
    area: {
      areaName: undefined
    }
  }

  serviceList: ServiceDto[] = [];
  submitting = false;
  roleRule = {
    serviceId: [
      {
        required: true,
        message: "服务标题必填",
        trigger: "blur",
      },
    ],
  };

  getServiceDetail() {
    this.loading = true;
    api.serviceMaintainService
      .get({id: this.form.serviceId})
      .then((res) => {
        this.serviceInfo = {...res}
      }).finally(() => {
      this.loading = false
    });
  }

  getServiceList() {
    api.serviceMaintainService.getAll({maxResultCount: 65535}).then((res) => {
      this.serviceList = res.items!;
    });
  }

  getTime() {
    console.log(this.serviceInfo.serviceStartTime, this.serviceInfo.serviceEndTime)
    if (this.serviceInfo.serviceStartTime && this.serviceInfo.serviceEndTime) {
      return moment(this.serviceInfo.serviceStartTime).format("YYYY-MM-DD HH:mm") + '至' + moment(this.serviceInfo.serviceEndTime).format("YYYY-MM-DD HH:mm")
    }
  }

  getCount() {
    if (this.serviceInfo.serviceObjectIds) {
      let serviceObjectList = this.serviceInfo.serviceObjectIds.split(',')
      return serviceObjectList.length
    }
  }

  getPlace() {
    let address = '';
    console.log(this.serviceInfo)
    if (this.serviceInfo && this.serviceInfo.province && this.serviceInfo.province.provinceName) {
      address += this.serviceInfo.province.provinceName
      if (this.serviceInfo.city) {
        address += this.serviceInfo.city.cityName
        if (this.serviceInfo.area) {
          address += this.serviceInfo.area.areaName
          if (this.serviceInfo.address) {
            address += this.serviceInfo.address
          }
        }
      }
    }
    if (this.serviceInfo && this.serviceInfo.address) {
      address += this.serviceInfo.address
    }
    return address;
    // if(this.serviceInfo.province || this.serviceInfo.city || this.serviceInfo.area || this.serviceInfo.address){
    //   return (this.serviceInfo.province?.provinceName + this.serviceInfo.city?.cityName + this.serviceInfo.area?.areaName + this.serviceInfo.address)?(this.serviceInfo.province?.provinceName + this.serviceInfo.city?.cityName + this.serviceInfo.area?.areaName + this.serviceInfo.address):''
    // }else{
    //   return ""
    // }
  }

  get getData() {
    if (this.dataId > 0)
      return api.serviceManagement.get({id: this.dataId});
    else
      return this.defaultData;
  }

  get hostType() {
    return CustomFormType.ServiceManagement;
  }

  get titleDisabled() {
    return this.dataId > 0;
  }

  // 保存
  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid && !this.isDetail) {
        (this.$refs.customForm as any).validate(async (valid2: boolean) => {
          if (valid2) {
            const data = (
              this.customForm as any
            ).getRequestData() as ServiceManagementCreateOrUpdateDto;
            data.extensionData = JSON.stringify(data.extensionObject);
            debugger;
            let submitForm: ServiceManagementCreateOrUpdateDto = {
              serviceId: this.form.serviceId,
              extensionObject: data.extensionObject,
              extensionData: data.extensionData,
              id: this.dataId,
              formId: data.formId,
              serviceOrganizationId: this.serviceInfo.serviceOrganizationId,
              volunteerCount: this.serviceInfo.signUpCount,
              serviceObjectCount: this.getCount(),
              basicInfo: this.serviceInfo.serviceOrganization?.basicInfo
            };

            let fn;
            if (this.dataId) fn = api.serviceManagement.update;
            else fn = api.serviceManagement.create;

            fn({body: submitForm}).then((res) => {
              if (this.dataId) {
                this.$message.success("更新成功");
              } else {
                this.$message.success("新增成功");
              }
              this.$router.push({
                name: "serviceManagement",
              });
            });
          } else {
            this.$message.error("请检查表单");
          }
        });
      } else {
        this.$message.error("请检查表单");
      }
    });
  }

  async created() {
    await this.getServiceList();

    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.serviceManagement.get({id: this.dataId}).then((res) => {
        this.form = res;
        this.getServiceDetail();
      });
    }

    if (this.$route.params.isDetail) {
      this.isDetail = true;
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  //返回
  cancel() {
    this.$router.back();
  }

}
